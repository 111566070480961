.m__bannerText {

    &__container {
        position: relative;
    }

    &__inner {
        position: relative;
    }

    &__figure {
        overflow: hidden;
    }

    &__section {
        box-sizing: border-box;
    }

    &__baseline {
        margin: 0;
    }
}