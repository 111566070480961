.m__globalForm {
    box-sizing: border-box;
}

input[type="checkbox"], input[type="radio"] {
    display: inline-block; width: 1.5rem; height: 1.5rem; margin-right: .5rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    vertical-align: sub;
    box-sizing: content-box;
    cursor: pointer;
}

input[type="radio"] {
    border-radius: 50%;
}
input[type="text"], input[type="tel"], input[type="email"], textarea {
    display: block; width: 100%;
    box-sizing: border-box;
}

textarea {
    min-height: 10rem;
    resize: vertical;
}

fieldset {
    margin: 0; padding: 0;
    border: none;

    label {
        margin-right: 1rem;
    }
}