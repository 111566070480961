button, html, input, select, textarea {
    font-family: $default-font-family; line-height: 1.3;
}

html {
    font-size: 62.5%; font-size: calc(1em * .625);
    overflow-x: hidden;
}

body {
    //font-size: $default-font-size * $media-ratio-sm;
    font-size: 1.4em;
}

h2, h3, h4 {
    margin-top: 0;
}

.m__titleText__content {

    .xx-small {
        font-size: .857em;
    }
    .x-small {
        font-size: 1em;
    }
    .small {
        font-size: 1.143em;
    }
    .medium {
        font-size: 1.286em;
    }
    .large {
        font-size: 1.5em;
    }
    .x-large {
        font-size: 1.714em;
    }
    .xx-large {
        font-size: 2.286em;
    }
}