.m__slider {
    position: relative;
    margin: {
        left: auto; 
        right: auto; 
    }
    overflow: hidden;

    &__item {
        display: none; width: 100%; height: 100%;
        opacity: 0; visibility: hidden;

        &.trigger {
            display: block;
            animation: fade .45s forwards;
        }
    }
    
    &__img {
        width: 100%; height: 100%;
        object-fit: cover;
    }

    &__control {

        &__item {
            position: absolute; top: 50%; 
            padding: 0;
            transform: translateY(-50%);
            border: none;
            background: none;
            z-index: 1;

            &.previous {
                left: 1rem;
            }

            &.next {
                right: 1rem;
            }
        }
    }

    &__bullet {
        position: absolute; bottom: .5rem; left: 50%;
        display: flex;
        align-items: center; justify-content: center;
        transform: translateX(-50%);

        &__item {
            padding: 0;
            border: none; 
            background: none;
            opacity: .5;
            z-index: 1;

            &.trigger {
                opacity: 1;
            }
            
            svg {
                display: block;
                border-radius: 50%;
                fill: $c-default;
            }
        }
    }
}