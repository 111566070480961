//Font
$system-font-stack   : -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$default-font-family :  $system-font-stack;
$title-font-family   : $system-font-stack;

//Color
$c-default           : #000;
$c-highlight         : #fff;

//Size 
$default-font-size   : 1em;
$default-line-height : 1;

$xx-small       : .857em;
$x-small        : 1em;
$small          : 1.143em;
$medium         : 1.286em;
$large          : 1.5em;
$x-large        : 1.714em;
$xx-large       : 2.286em;

//Wrapper
$wrapper-size-md     : 59.6rem;
$wrapper-size-hv     : 95rem;
$wrapper-size-xl     : 118rem;

//Ratio
$media-ratio-tn      : 1.5;
$media-ratio-sm      : 1.6;
$media-ratio-md      : 1.7;
$media-ratio-xl      : 2;

$media-breakpoint-sm : 48em;
$media-breakpoint-md : 62em;
$media-breakpoint-xl : 75em;


//Transition
$tiny-timing         : .15s;
$default-timing      : .25s;
$default-animation   : cubic-bezier(unquote($ease-out));
$default-transition  : all .2s;
$tiny-transition     : all $tiny-timing $default-animation;


//Svg