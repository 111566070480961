.cookiebar {
    position: fixed; left: 0; right: 0;
    display: flex; padding: .5rem 1rem;
    flex-flow: row wrap; justify-content: center; align-items: center; 
    box-sizing: border-box;
    z-index: 100;

    & > * {
        margin: .5rem 1.5rem;
    }
}