.xx-small {
    font-size: $xx-small;
}
.x-small {
    font-size: $x-small;
}
.small {
    font-size: $small;
}
.medium {
    font-size: $medium;
}
.large {
    font-size: $large;
}
.x-large {
    font-size: $x-large;
}
.xx-large {
    font-size: $xx-large;
}