.m__fixedForm {
    position: fixed; top: 0; left: 0; right: 0; bottom: 0;
    // max-width: 40rem; 
    transform: translate3d(4rem, 0, 0);
    box-sizing: border-box;
    opacity: 0; visibility: hidden;
    overflow-y: auto; overflow-x: hidden;
    z-index: 50;
    transition: $default-transition;

    @include media ($media-breakpoint-md) {
        left: auto; bottom: auto; 
        max-width: 40rem;
    }

    &.trigger {
        transform: translate3d(0, 0, 0);
        opacity: 1; visibility: visible;
    }

    &__trigger {
        position: fixed; top: 0; right: 0;
        display: none; padding: 1rem;
        z-index: 51;

        &.scrolled {
            display: block;
        }

        &.trigger {
            opacity: 0; visibility: hidden;
            transition: $default-transition;
        }
    }

    &__title {
        margin-top: 4rem;
    }

    &__title, &__item {
        max-width: 40rem; 
        margin: {
            left: auto;
            right: auto; 
        }
    }

    &__field {
        margin: 1rem 0;
    }

    &__fieldset {
        margin: 0; padding: 0;
        border: none;

        &__inner {
            display: flex;
            flex-flow: row wrap;
        }
    }

    &__legend {
        display: block; margin-bottom: .25rem;
    }

    &__label {

        &__radio {
            display: flex; margin: 0 1rem 1rem 0;

            span {
                margin: 0;
            }
        }

        &__item {
            display: block; margin-bottom: .25rem;
        }

        &__checkbox {
            display: flex;
        }
    }

    &__radio__input, &__checkbox {
        width: 1.5rem; height: 1.5rem; margin-right: .5rem;
        flex: 0 0 auto;
        border: solid .1rem #000;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        &:checked {
            border-color: #000;
            background-color: #000;
        }
    }

    &__radio__input {
        border-radius: 50%;
    }

    &__input__text, &__textarea {
        display: block; width: 100%; padding: .25rem .5rem;
        border: solid .1rem $c-default;
        background-color: $c-highlight;
        box-sizing: border-box;

        &:focus {
            outline: none;
        }
    }

    &__button {
        position: relative;

        svg {
            position: absolute; top: 50%; right: .5rem;
            display: block; width: 1em; height: 1em;
            transform: translateY(-50%);
        }
    }

    &__closer {
        position: absolute; top: 1rem; right: 1rem;
        border: none; 
        background: none;
    }
}