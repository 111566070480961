.m__wrapper {
    max-width: 120rem;
    margin: {
        left: auto; 
        right: auto;
    }
    //padding: {
    //    left: .8rem;
    //    right: .8rem;
    //}
}