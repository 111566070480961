.visuallyhidden {
	@extend %visuallyhidden;
}

.align {

	&--left {
		margin-left: 0; margin-right: auto;
	}

	&--center {
		margin-left: auto; margin-right: auto;
	}

	&--right {
		margin-left: auto; margin-right: 0;
	}
}

.fontsize {

	&--tn {
		font-size: .75em;
	}

	&--sm {
		font-size: .875em;
	}

	&--md {
		font-size: 1em;
	}

	&--lg {
		font-size: 1.125em;
	}

	&--hv {
		font-size: 1.25em;
	}
}

.lh {

	&-small {
		line-height: 1;
	}

	&-medium {
		line-height: 1.2;
	}

	&-large {
		line-height: 1.4;
	}
}

.ql {

	&-align-center {
		text-align: center;
	}

	&-align-right {
		text-align: right;
	}

	&-align-justify {
		text-align: justify;
	}
}
// .visuallyvisible {
// 	@extend %visuallyvisible;
// }

