body {
    padding: 0;
    overflow-x: hidden;

    &.overflow {
        overflow-y: hidden;

        @include media ($media-breakpoint-md) {
            overflow-y: auto; 
        }
    }
}

figure {
    margin: 0;

    img {
        display: block; 
    }
}

img {
    max-width: 100%; height: auto;

    &[data-loaded] {
        transition: $default-transition;
    }

    &[data-loaded="false"] {
        opacity: 0; visibility: hidden;
    }

    &[data-loaded="true"] {
        opacity: 1; visibility: visible;
    }
}

button {
    border-radius: 0; /* Fix Chrome */
    cursor: pointer;

    &:focus {
        outline: none;
    }

    * {
        pointer-events: none;
    }
}

abbr[title] {
    text-decoration: none;
}

textarea {
    resize: vertical;
}