.m__bannerLogo {

    &__item {
        position: relative; 
        overflow: hidden;
        z-index: 1;
    }

    &__img {

        @supports (object-fit: scale-down) {
            width: 100%; height: 100%;
            object-fit: scale-down;
        }
    }

    &__inner {
        display: flex;
        flex-wrap: wrap;
    }

    &__container {
        h2, h3, h4 {
            margin: 0;
        }
    }
}