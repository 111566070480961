.m__threeColumns {

    &__inner {
        
        @include media ($media-breakpoint-md) {
            display: flex; 
            overflow: hidden;
        }
    }

    &__col {
        
        @include media ($media-breakpoint-md) {
            display: flex; 
            flex-flow: column nowrap;
            flex: 1 1 33%; flex-basis: calc(100% / 3);
        }

        &:nth-child(2) {
            
            @include media ($media-breakpoint-md) {
                flex-flow: column-reverse nowrap;
            }
        }
    }

    &__section {
        box-sizing: border-box;
    }

    &__figure {
        overflow: hidden;
    }

    &__button {
        position: relative; 

        svg {
            position: absolute; top: 50%; right: 1rem; 
            width: 1em; height: 1em;
            transform: translateY(-50%);
        }
    }
}