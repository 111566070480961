.m__video {

    &__container {
        overflow: hidden;

        video, iframe {
            width: 100%; height: auto;
        }

        iframe {
            display: block;
        }
    }

    &__item {
        display: block; 
    }
}